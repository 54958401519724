import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useCart } from '../../context/CartContext';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Badge,
} from '@mui/material';
import {
  Person as PersonIcon,
  Favorite as FavoriteIcon,
  ShoppingCart as CartIcon,
  Search as SearchIcon,
  Dashboard as DashboardIcon,
  ExitToApp as LogoutIcon,
} from '@mui/icons-material';
import logo from '../assets/logo.png';
import './Navbar.css';

const Navbar = ({ onCartOpen }) => {
  const navigate = useNavigate();
  const { isAuthenticated, userData, logout } = useAuth();
  const { cartCount } = useCart();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    logout();
    handleCloseMenu();
    navigate('/');
  };

  return (
    <nav className="navbar">
      <div className="nav-menu">
        <Link to="/new-arrivals" className="nav-link">New Arrivals</Link>
        <Link to="/women" className="nav-link">Women</Link>
        <Link to="/men" className="nav-link">Men</Link>
        <Link to="/sale" className="nav-link">Sale</Link>
        <Link to="/collections" className="nav-link">Collections</Link>
      </div>
      
      <div className="nav-brand">
        <Link to="/">
          <img src={logo} alt="OpDrape" className="brand-logo" />
        </Link>
      </div>
      
      <div className="nav-actions">
        <div className="search-container">
          <input type="text" placeholder="Search products..." className="search-input" />
          <IconButton size="small" sx={{ color: 'text.secondary' }}>
            <SearchIcon />
          </IconButton>
        </div>
        <div className="user-actions">
          {isAuthenticated ? (
            <>
              <Link to="/wishlist" className="icon-link">
                <FavoriteIcon />
                <span>Wishlist</span>
              </Link>
              <div className="icon-link" onClick={onCartOpen} style={{ cursor: 'pointer' }}>
                <Badge badgeContent={cartCount} color="primary">
                  <CartIcon />
                </Badge>
                <span>Cart</span>
              </div>
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleOpenMenu}
                    size="small"
                  >
                    <Avatar 
                      sx={{ 
                        width: 32, 
                        height: 32, 
                        bgcolor: 'primary.main',
                        fontSize: '0.875rem'
                      }}
                    >
                      {userData?.name?.charAt(0)?.toUpperCase() || <PersonIcon />}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Typography
                  variant="subtitle2"
                  sx={{ 
                    ml: 1, 
                    cursor: 'pointer',
                    '&:hover': { color: 'primary.main' }
                  }}
                  onClick={handleOpenMenu}
                >
                  {userData?.name || 'User'}
                </Typography>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
              >
                <MenuItem component={Link} to="/dashboard">
                  <DashboardIcon sx={{ mr: 1 }} />
                  Dashboard
                </MenuItem>
                <MenuItem onClick={handleLogoutClick}>
                  <LogoutIcon sx={{ mr: 1 }} />
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Link to="/account" className="icon-link">
                <PersonIcon />
                <span>Account</span>
              </Link>
              <Link to="/wishlist" className="icon-link">
                <FavoriteIcon />
                <span>Wishlist</span>
              </Link>
              <div className="icon-link" onClick={onCartOpen} style={{ cursor: 'pointer' }}>
                <Badge badgeContent={cartCount} color="primary">
                  <CartIcon />
                </Badge>
                <span>Cart</span>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 