import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info">
          <img src="/logo.png" alt="OpDrape" className="footer-logo" />
          <p>
            Opdrape Fashion Ltd. providing elegance & luxurious outfit items 
            sourced both locally & globally. Proudly Made in Bangladesh!
          </p>
          <div className="social-links">
            <a href="/"><i className="fab fa-instagram"></i></a>
            <a href="/"><i className="fab fa-twitter"></i></a>
            <a href="/"><i className="fab fa-youtube"></i></a>
            <a href="/"><i className="fab fa-facebook-f"></i></a>
          </div>
        </div>

        <div className="contact-info">
          <p><i className="far fa-envelope"></i> info@opdrape.com</p>
          <p><i className="fas fa-phone"></i> +880 1845272691</p>
          <p className="address">
            Bou Bazaar, Akran, Birulia<br />
            Ashulia,Savar<br />
            Dhaka-1216
          </p>
        </div>

        <div className="footer-links">
          <h3>Services & Help</h3>
          <ul>
            <li><a href="/">Exchange Policy</a></li>
            <li><a href="/">Terms of Services & Conditions</a></li>
            <li><a href="/">About Us</a></li>
            <li><a href="/">Contact us</a></li>
            <li><a href="/">Career</a></li>
          </ul>
          <button className="store-locator">
            OPDRAPE STORE LOCATOR
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 