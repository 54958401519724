import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import Navbar from './components/Navbar/Navbar';
import Banner from './components/Banner/Banner';
import ProductSection from './components/ProductSection/ProductSection';
import Footer from './components/Footer/Footer';
import Login from './components/Login';
import UserDashboard from './components/Dashboard/UserDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import Women from './components/pages/Women';
import Men from './components/pages/Men';
import Sale from './components/pages/Sale';
import Cart from './components/Cart/Cart';
import Checkout from './components/Checkout/Checkout';
import { initializeProducts } from './data/mockProducts';
import theme from './theme';
import './styles/global.css';

function App() {
  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    // Initialize products when app starts
    initializeProducts();
  }, []);

  const handleCartOpen = () => {
    setCartOpen(true);
  };

  const handleCartClose = () => {
    setCartOpen(false);
  };

  // Component for the home page content
  const HomeContent = () => (
    <>
      <Banner />
      <ProductSection />
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CartProvider>
          <Router>
            <div className="app">
              <Navbar onCartOpen={handleCartOpen} />
              <main className="main-content">
                <Routes>
                  {/* Admin Routes */}
                  <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />
                  <Route path="/admin/login" element={<AdminLogin />} />
                  <Route path="/admin/dashboard/*" element={
                    <AdminRoute>
                      <AdminDashboard />
                    </AdminRoute>
                  } />

                  {/* Regular Routes */}
                  <Route path="/" element={<HomeContent />} />
                  <Route path="/account" element={<Login />} />
                  <Route path="/dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
                  <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
                  <Route path="/women" element={<Women />} />
                  <Route path="/men" element={<Men />} />
                  <Route path="/sale" element={<Sale />} />
                </Routes>
              </main>
              <Cart open={cartOpen} onClose={handleCartClose} />
              <Footer />
            </div>
          </Router>
        </CartProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
