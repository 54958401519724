import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from '../config/axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkAuthStatus = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAuthenticated(false);
        setUserRole(null);
        setUserData(null);
        setLoading(false);
        return;
      }

      const response = await axios.get('/auth/verify');
      const data = response.data;

      if (data.success && data.user) {
        setIsAuthenticated(true);
        setUserRole(data.user.role || 'user');
        setUserData(data.user);
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
        setUserData(null);
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error('Auth check error:', error);
      setIsAuthenticated(false);
      setUserRole(null);
      setUserData(null);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (token, user) => {
    try {
      if (!token) {
        throw new Error('No token provided');
      }

      // Ensure we have the minimum required user data
      const userData = {
        _id: user._id || user.id,
        name: user.name || 'User',
        email: user.email,
        role: user.role || 'user'
      };

      // Set token in localStorage and axios defaults
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Update state
      setIsAuthenticated(true);
      setUserRole(userData.role);
      setUserData(userData);

      console.log('Login successful:', {
        isAuthenticated: true,
        userRole: userData.role,
        userData
      });
    } catch (error) {
      console.error('Login error in AuthContext:', error);
      logout();
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserRole(null);
    setUserData(null);
    
    // Remove token from axios defaults
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      userRole, 
      userData, 
      loading, 
      login, 
      logout,
      checkAuthStatus 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 