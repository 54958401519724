import React from 'react';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  ShoppingBag as ProductsIcon,
  ShoppingCart as OrdersIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

// Import admin components
import AdminOverview from './AdminOverview';
import AdminProducts from './AdminProducts';
import AdminOrders from './AdminOrders';
import AdminCustomers from './AdminCustomers';
import AdminSettings from './AdminSettings';

const drawerWidth = 240;

const AdminDashboard = () => {
  const theme = useTheme();
  const location = useLocation();

  const menuItems = [
    { text: 'Overview', icon: <DashboardIcon />, path: '' },
    { text: 'Products', icon: <ProductsIcon />, path: 'products' },
    { text: 'Orders', icon: <OrdersIcon />, path: 'orders' },
    { text: 'Customers', icon: <PeopleIcon />, path: 'customers' },
    { text: 'Settings', icon: <SettingsIcon />, path: 'settings' },
  ];

  const getFullPath = (path) => `/admin/dashboard${path ? `/${path}` : ''}`;

  return (
    <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 64px)' }}>
      {/* Sidebar */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            marginTop: '64px', // Height of navbar
          },
        }}
      >
        <Box sx={{ overflow: 'auto', mt: 2 }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                component={Link}
                to={getFullPath(item.path)}
                selected={location.pathname === getFullPath(item.path)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    '& .MuiListItemIcon-root': {
                      color: 'white',
                    },
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  mb: 1,
                  mx: 1,
                  borderRadius: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname === getFullPath(item.path) ? 'white' : theme.palette.text.primary,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: theme.palette.background.default,
          minHeight: '100%',
        }}
      >
        <Routes>
          <Route index element={<AdminOverview />} />
          <Route path="products" element={<AdminProducts />} />
          <Route path="orders" element={<AdminOrders />} />
          <Route path="customers" element={<AdminCustomers />} />
          <Route path="settings" element={<AdminSettings />} />
          <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminDashboard; 