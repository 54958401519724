import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
  TextField,
  Button,
  Divider,
  useTheme,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';

const SettingsSection = ({ title, children }) => (
  <Paper sx={{ p: 3, borderRadius: 2, mb: 3 }}>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Divider sx={{ mb: 3 }} />
    {children}
  </Paper>
);

const AdminSettings = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <SettingsSection title="Store Settings">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Store Name"
                  defaultValue="OpDrape"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Store Description"
                  defaultValue="Your one-stop fashion destination"
                  multiline
                  rows={3}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Contact Email"
                  defaultValue="contact@opdrape.com"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Contact Phone"
                  defaultValue="+91 1234567890"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </SettingsSection>

          <SettingsSection title="Notification Settings">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="Email notifications for new orders"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="Email notifications for low stock"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch />}
                  label="Email notifications for customer reviews"
                />
              </Grid>
            </Grid>
          </SettingsSection>
        </Grid>

        <Grid item xs={12} md={4}>
          <SettingsSection title="System Settings">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="Maintenance Mode"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="Allow Guest Checkout"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label="Show Out of Stock Products"
                />
              </Grid>
            </Grid>
          </SettingsSection>

          <Box sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SaveIcon />}
              sx={{
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
              }}
            >
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminSettings; 