import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1a1a1a',
    },
    secondary: {
      main: '#9c8b7c',
    },
    background: {
      default: '#ffffff',
      paper: '#fafafa',
    },
    text: {
      primary: '#1a1a1a',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontFamily: "'Cormorant Garamond', serif",
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    h2: {
      fontFamily: "'Cormorant Garamond', serif",
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    h3: {
      fontFamily: "'Cormorant Garamond', serif",
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    h4: {
      fontFamily: "'Cormorant Garamond', serif",
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    h5: {
      fontFamily: "'Cormorant Garamond', serif",
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    h6: {
      fontFamily: "'Cormorant Garamond', serif",
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    subtitle1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      letterSpacing: '0.05em',
    },
    subtitle2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      letterSpacing: '0.05em',
    },
    body1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      letterSpacing: '0.03em',
    },
    body2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      letterSpacing: '0.03em',
    },
    button: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 500,
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: '12px 24px',
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
  },
});

export default theme; 