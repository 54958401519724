import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <section className="banner">
      <h1 className="section-title">NEW ARRIVALS</h1>
      <div className="banner-content">
        <div className="product-info">
          <h2>
            <span>Men's</span>
            <span>Casual</span>
            <span>Hoodie</span>
          </h2>
          <p className="description">
            100% Cotton, Model is 6'1" And Wears Size M
          </p>
          <button className="add-cart-btn">+ Add to Cart</button>
          <div className="social-actions">
            <button>Share</button>
            <button>Tweet</button>
          </div>
        </div>

        <div className="product-image">
          <div className="image-container">
            <span className="price-tag">₹1275.00</span>
            <img src="/hoodie-image.jpg" alt="Men's Casual Hoodie" />
          </div>
        </div>

        <div className="product-details">
          <div className="info-section">
            <h3>Product Info</h3>
            <p>Premium Comfort 250GSM fabric for a lightweight, cozy feel. Unique Design inspired by Valorant & Arcane, blending fandom with style. Durable Freshness No-lint technology for a clean, polished look every time.</p>
          </div>
          <div className="delivery-section">
            <h3>Delivery Info</h3>
            <p>Standard delivery within 3-5 business days</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner; 