import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import ProductCard from './ProductCard';
import axios from '../config/axios';

const ProductList = ({ category, subcategory, collection, isNewArrival, onSale, featured }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchProducts();
  }, [category, subcategory, collection, isNewArrival, onSale, featured]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      // Build query parameters
      const params = new URLSearchParams();
      if (category) params.append('category', category);
      if (isNewArrival) params.append('isNewArrival', true);
      if (onSale) params.append('onSale', true);

      console.log('Fetching products with params:', params.toString());
      const response = await axios.get(`/products?${params.toString()}`);
      console.log('Products response:', response.data);

      if (response.data.success) {
        setProducts(response.data.products || []);
      } else {
        throw new Error(response.data.message || 'Failed to fetch products');
      }
    } catch (error) {
      console.error('Error loading products:', error);
      setError(error.message || 'Failed to load products');
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (products.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', p: 4 }}>
        <Typography variant="h6" color="text.secondary">
          No products found in this category.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
          <ProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductList; 