import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from '../config/axios';
import {
  Box,
  Container,
  Paper,
  Tabs,
  Tab,
  TextField,
  Button,
  Typography,
  Alert,
  Divider,
  CircularProgress
} from '@mui/material';

const Login = () => {
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setError('');
    setEmail('');
    setPassword('');
    setName('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      console.log('Login attempt with:', { email });
      const response = await axios.post('/auth/login', { 
        email, 
        password 
      });
      
      console.log('Raw response:', response);
      console.log('Login response data:', response.data);
      
      // Check if we have a successful response
      if (response.data && response.data.success) {
        const userData = response.data.data || response.data.user;
        console.log('User data:', userData);

        if (!userData) {
          throw new Error('No user data received from server');
        }

        const token = userData.token || response.data.token;
        if (!token) {
          throw new Error('No token received from server');
        }

        // Store the token
        localStorage.setItem('token', token);
        
        // Ensure we have a complete user object
        const user = {
          _id: userData._id,
          name: userData.name,
          email: userData.email,
          role: userData.role || 'user'
        };

        console.log('Processed user data:', user);
        
        // Update auth context with user data
        await login(token, user);
        navigate('/dashboard');
      } else {
        const errorMessage = response.data.message || 'Login failed';
        console.error('Login failed:', errorMessage);
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.error('Login error details:', {
        message: err.message,
        response: err.response?.data,
        error: err,
        stack: err.stack
      });
      
      // Handle specific error messages from the backend
      if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else if (err.response?.status === 401) {
        setError('Invalid email or password');
      } else if (err.response?.status === 404) {
        setError('User not found');
      } else {
        setError(err.message || 'An error occurred during login. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('/auth/register', {
        name,
        email,
        password
      });
      
      console.log('Registration response:', response.data);

      if (response.data && response.data.success) {
        setTab(0);
        setEmail('');
        setPassword('');
        setName('');
        setError('✓ Registration successful! Please login with your credentials.');
      } else {
        console.error('Invalid registration response:', response.data);
        throw new Error(response.data.message || 'Registration failed');
      }
    } catch (err) {
      console.error('Registration error:', {
        message: err.message,
        response: err.response?.data,
        error: err
      });
      
      if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else if (err.response?.status === 409) {
        setError('Email already exists');
      } else {
        setError(err.message || 'An error occurred during registration. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (tab === 0) {
      return email && password;
    }
    return email && password && name && password.length >= 6;
  };

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          My Account
        </Typography>
        
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{ mb: 4 }}
        >
          <Tab label="Login" />
          <Tab label="Register" />
        </Tabs>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {tab === 0 ? (
    <Box component="form" onSubmit={handleLogin}>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Email Address"
              type="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={!validateForm() || loading}
        sx={{ mt: 3, mb: 2, py: 1.5 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Sign In'}
      </Button>
      <Divider sx={{ my: 2 }}>
        <Typography variant="body2" color="text.secondary">
          OR
        </Typography>
      </Divider>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setTab(1)}
        disabled={loading}
        sx={{ py: 1.5 }}
      >
        Create New Account
      </Button>
    </Box>
        ) : (
    <Box component="form" onSubmit={handleRegister}>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Full Name"
        autoFocus
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={loading}
        error={name === ''}
        helperText={name === '' ? 'Name is required' : ''}
        InputProps={{
          sx: { borderRadius: 1 }
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Email Address"
        type="email"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
        error={email === ''}
        helperText={email === '' ? 'Email is required' : ''}
        InputProps={{
          sx: { borderRadius: 1 }
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Password"
        type="password"
        autoComplete="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
        error={password.length > 0 && password.length < 6}
        helperText={password.length > 0 && password.length < 6 
          ? 'Password must be at least 6 characters' 
          : 'Password must be at least 6 characters'}
        InputProps={{
          sx: { borderRadius: 1 }
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={!validateForm() || loading}
        sx={{ 
          mt: 3, 
          mb: 2, 
          py: 1.5,
          bgcolor: 'primary.main',
          color: 'white',
          borderRadius: 1,
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 500,
          '&:hover': {
            bgcolor: 'primary.dark'
          },
          '&.Mui-disabled': {
            bgcolor: 'action.disabledBackground',
            color: 'action.disabled'
          }
        }}
      >
        {loading ? (
          <CircularProgress size={24} sx={{ color: 'inherit' }} />
        ) : (
          'Create Account'
        )}
      </Button>
      <Divider sx={{ my: 2 }}>
        <Typography variant="body2" color="text.secondary">
          OR
        </Typography>
      </Divider>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setTab(0)}
        disabled={loading}
        sx={{ 
          py: 1.5,
          borderRadius: 1,
          textTransform: 'none',
          fontSize: '1rem',
          borderColor: 'primary.main',
          color: 'primary.main',
          '&:hover': {
            borderColor: 'primary.dark',
            bgcolor: 'primary.50'
          }
        }}
      >
        Sign In Instead
      </Button>
    </Box>
        )}
      </Paper>
    </Container>
  );
};

export default Login; 