import React from 'react';
import { Box, Typography, Container, Breadcrumbs, Link } from '@mui/material';
import ProductList from '../ProductList';
import { styled } from '@mui/material/styles';

const PageContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 0),
}));

const HeroSection = styled(Box)(({ theme }) => ({
  height: '40vh',
  backgroundImage: 'url(/images/men-hero.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(6),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  }
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  position: 'relative',
  zIndex: 1,
  fontFamily: "'Cormorant Garamond', serif",
  fontWeight: 300,
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Cormorant Garamond', serif",
  fontWeight: 300,
  fontSize: '1.5rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiLink-root': {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const Men = () => {
  return (
    <Box>
      <HeroSection>
        <PageTitle variant="h2">
          Men's Collection
        </PageTitle>
      </HeroSection>

      <PageContainer maxWidth="xl">
        <StyledBreadcrumbs aria-label="breadcrumb">
          <Link href="/">Home</Link>
          <Typography color="text.primary">Men</Typography>
        </StyledBreadcrumbs>

        <SubTitle>
          Discover refined style in our curated collection
        </SubTitle>

        <ProductList category="men" />
      </PageContainer>
    </Box>
  );
};

export default Men; 