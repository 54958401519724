import React from 'react';
import './ProductSection.css';

const ProductSection = () => {
  return (
    <section className="product-section">
      <h2 className="section-title">OUR COLLECTIONS</h2>
      <div className="collections-grid">
        {/* Add collection items here when needed */}
      </div>
    </section>
  );
};

export default ProductSection; 