import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Avatar,
  Divider,
} from '@mui/material';
import {
  Person as PersonIcon,
  ShoppingBag as OrdersIcon,
  Favorite as WishlistIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import UserOrders from './UserOrders';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`dashboard-tabpanel-${index}`}
    aria-labelledby={`dashboard-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
  </div>
);

const UserDashboard = () => {
  const { userData } = useAuth();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        {/* User Profile Summary */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                sx={{
                  width: 60,
                  height: 60,
                  bgcolor: 'primary.main',
                  fontSize: '1.5rem',
                }}
              >
                {userData?.name?.charAt(0)?.toUpperCase() || <PersonIcon />}
              </Avatar>
              <Box>
                <Typography variant="h5">
                  Welcome, {userData?.name || 'User'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userData?.email}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Dashboard Content */}
        <Grid item xs={12}>
          <Paper sx={{ borderRadius: 2 }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ px: 2, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab
                icon={<OrdersIcon />}
                label="My Orders"
                iconPosition="start"
                sx={{ minHeight: 64 }}
              />
              <Tab
                icon={<WishlistIcon />}
                label="Wishlist"
                iconPosition="start"
                sx={{ minHeight: 64 }}
              />
              <Tab
                icon={<SettingsIcon />}
                label="Settings"
                iconPosition="start"
                sx={{ minHeight: 64 }}
              />
            </Tabs>

            <Box sx={{ p: 3 }}>
              <TabPanel value={activeTab} index={0}>
                <UserOrders />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Typography variant="h6" color="text.secondary" align="center">
                  Wishlist Coming Soon
                </Typography>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Typography variant="h6" color="text.secondary" align="center">
                  Settings Coming Soon
                </Typography>
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserDashboard; 