export const initialProducts = [
  {
    id: 1,
    name: 'Floral Summer Dress',
    description: 'A beautiful floral print dress perfect for summer',
    price: 1299,
    category: 'women',
    image: 'https://images.unsplash.com/photo-1515372039744-b8f02a3ae446?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    isNewArrival: true,
    onSale: false,
  },
  {
    id: 2,
    name: 'Classic White Blouse',
    description: 'Elegant white blouse for any occasion',
    price: 899,
    category: 'women',
    image: 'https://images.unsplash.com/photo-1554568218-0f1715e72254?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    isNewArrival: false,
    onSale: true,
  },
  {
    id: 3,
    name: 'Slim Fit Jeans',
    description: 'Classic blue slim fit jeans',
    price: 1499,
    category: 'men',
    image: 'https://images.unsplash.com/photo-1542272604-787c3835535d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    isNewArrival: true,
    onSale: false,
  },
  {
    id: 4,
    name: 'Casual T-Shirt',
    description: 'Comfortable cotton t-shirt',
    price: 599,
    category: 'men',
    image: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    isNewArrival: false,
    onSale: true,
  },
  {
    id: 5,
    name: 'Printed Maxi Dress',
    description: 'Long flowing maxi dress with print',
    price: 1899,
    category: 'women',
    image: 'https://images.unsplash.com/photo-1496747611176-843222e1e57c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    isNewArrival: true,
    onSale: false,
  },
  {
    id: 6,
    name: 'Formal Shirt',
    description: 'Classic formal shirt for office wear',
    price: 1299,
    category: 'men',
    image: 'https://images.unsplash.com/photo-1563630423918-b58f07336ac5?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
    isNewArrival: false,
    onSale: false,
  },
];

// Function to initialize products in localStorage
export const initializeProducts = () => {
  const existingProducts = localStorage.getItem('adminProducts');
  if (!existingProducts) {
    localStorage.setItem('adminProducts', JSON.stringify(initialProducts));
  }
}; 